import * as React from 'react';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';

import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';

export default function Navigation(user) {
    const userDetails = user.user.user

    return (
        <List
            size="sm"
            sx={{'--ListItem-radius': 'var(--joy-radius-sm)', '--List-gap': '4px'}}
        >
            <ListItem nested>
                <ListSubheader sx={{letterSpacing: '2px', fontWeight: '800'}}>
                    Boards
                </ListSubheader>
                <List
                    aria-labelledby="nav-list-browse"
                    sx={{
                        '& .JoyListItemButton-root': {p: '8px'},
                    }}
                >
                    <ListItem>
                        <ListItemButton selected>
                            <ListItemDecorator>
                                <PeopleRoundedIcon fontSize="small"/>
                            </ListItemDecorator>
                            <ListItemContent>{userDetails.given_name}'s board</ListItemContent>
                        </ListItemButton>
                    </ListItem>
                </List>
            </ListItem>
        </List>
    );
}