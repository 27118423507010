/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
// @ts-ignore
import TwoSidedLayout from '../components/TwoSidedLayout.tsx';
import {useNavigate} from "react-router-dom";

interface props {
  login: () => void;
  isAuthenticated: boolean;
}

export default function Hero({login, isAuthenticated}: props) {

  const navigate = useNavigate();
  const navigateToDashboard = () => {
    navigate("/dashboard");
  }
  return (
    <TwoSidedLayout>
      <Typography color="primary" level="h1"
                  fontWeight="xl"
                  fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)">
        Toppi
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        A simple tracker to keep on top of your home search.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Save and compare your favourite properties, set up alerts for new properties, and make
        direct contact with local agents.
      </Typography>
      <Button size="lg" endDecorator={<ArrowForward fontSize="large"/>}
              onClick={isAuthenticated ? navigateToDashboard : login}>
        Get started
      </Button>
      <Typography>
        Already a member? <Button variant="plain" onClick={login}>Log in</Button>
      </Typography>
    </TwoSidedLayout>
  );
}