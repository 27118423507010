import React from 'react';
import Layout from '../components/Layout';
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import Hero from "../components/Hero.tsx";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import Dropdown from "@mui/joy/Dropdown";
import MenuButton from "@mui/joy/MenuButton";
import Avatar from "@mui/joy/Avatar";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListDivider from "@mui/joy/ListDivider";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

export default function Homepage() {
    const {logout, login, isAuthenticated} = useKindeAuth();
    const user = useKindeAuth();

    const handleLogin = async () => {
        try {
            await login();
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const userDetails = user.user

    return (
        <>
            <Layout.Header>
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'space-between',
                        backgroundColor: 'background.surface',
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        sx={{display: {xs: 'none', sm: 'flex'}}}
                    >
                        <Typography level="h3">Toppi</Typography>
                    </Stack>
                    {user.isAuthenticated ? (
                        <Dropdown>
                            <MenuButton
                                variant="plain"
                                size="sm"
                                sx={{maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px'}}
                            >
                                <Avatar
                                    src="https://images7.alphacoders.com/331/thumbbig-331035.webp"
                                    srcSet="https://images7.alphacoders.com/331/thumbbig-331035.webp"
                                    sx={{maxWidth: '32px', maxHeight: '32px'}}
                                />
                            </MenuButton>
                            <Menu
                                placement="bottom-end"
                                size="sm"
                                sx={{
                                    zIndex: '99999',
                                    p: 1,
                                    gap: 1,
                                    '--ListItem-radius': 'var(--joy-radius-sm)',
                                }}
                            >
                                <MenuItem>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Avatar
                                            src="https://images7.alphacoders.com/331/thumbbig-331035.webp"
                                            srcSet="https://images7.alphacoders.com/331/thumbbig-331035.webp"
                                            sx={{borderRadius: '50%'}}
                                        />
                                        <Box sx={{ml: 1.5}}>
                                            <Typography level="title-sm" textColor="text.primary">
                                                {`${userDetails.given_name} ${userDetails.family_name}`}
                                            </Typography>
                                            <Typography level="body-xs" textColor="text.tertiary">
                                                {userDetails.email}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </MenuItem>
                                <ListDivider/>
                                <MenuItem onClick={logout}>
                                    <LogoutRoundedIcon/>
                                    Log out
                                </MenuItem>
                            </Menu>
                        </Dropdown>) : (
                        <Button
                            color="neutral"
                            onClick={login}
                            variant="soft"
                        >
                            Login
                        </Button>
                    )}
                </Box>
            </Layout.Header>
            <Hero login={handleLogin} isAuthenticated={isAuthenticated}/>
        </>
    );
}