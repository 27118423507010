import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Card, ListItem} from "@mui/joy";
import CardOverflow from "@mui/joy/CardOverflow";
import AspectRatio from "@mui/joy/AspectRatio";

type SortableTaskItemProps = {
  children: React.ReactNode;
  id: string;
  house: any;
};

const SortableTaskItem = ({children, id, house}: SortableTaskItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <ListItem sx={{alignSelf: 'center', padding: 'auto'}}>
        <Card variant="outlined" sx={{width: '280px'}}>
          <CardOverflow>
            <AspectRatio ratio="2">
              <img
                src={house.photos[0]}
                srcSet={house.photos[0]}
                loading="lazy"
                alt=""
                {...attributes} {...listeners}
              />
            </AspectRatio>
            {children}
          </CardOverflow>
        </Card>
      </ListItem>
    </div>
  );
};

export default SortableTaskItem;
