import {useMutation, useQueryClient} from "@tanstack/react-query";
import {APP_API} from "./App";

function DeleteHouse() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (data) => {
            console.log(data);
            await fetch(`${APP_API}/api/house/${data}`, {
                method: 'DELETE'
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['house']})
        }
    })
}

export default DeleteHouse;