// import * as React from 'react';
// import Button from '@mui/material/Button';
// import {Container, styled} from "@mui/material";
// import MenuItem from '@mui/material/MenuItem';
// import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
// import {Link} from "react-router-dom";
// import PrelloLogo from "../helpers/prelloLogo.svg";
//
// export default function Navbar() {
//     const [anchorEl, setAnchorEl] = React.useState(null);
//     const open = Boolean(anchorEl);
//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };
//     const handleClose = () => {
//         setAnchorEl(null)
//     };
//
//
//     return (
//         <>
//             <NavbarWrapper maxWidth={false}>
//                 <NavbarItems>
//                     <Link style={{textDecoration: "none", color: "#000000"}} to="/">
//                         <img src={PrelloLogo} alt="Prello Logo" style={{width: "160px"}} />
//                     </Link>
//                 </NavbarItems>
//                 <Button
//                     id="demo-positioned-button"
//                     aria-controls={open ? 'demo-positioned-menu' : undefined}
//                     aria-haspopup="true"
//                     aria-expanded={open ? 'true' : undefined}
//                     onClick={handleClick}
//                 >
//                     <MenuIcon
//                         htmlColor={"#000000"}
//                     />
//                 </Button>
//                 <Menu
//                     id="demo-positioned-menu"
//                     aria-labelledby="demo-positioned-button"
//                     anchorEl={anchorEl}
//                     open={open}
//                     onClose={handleClose}
//                     anchorOrigin={{
//                         vertical: 'top',
//                         horizontal: 'left',
//                     }}
//                     transformOrigin={{
//                         vertical: 'top',
//                         horizontal: 'left',
//                     }}
//                 >
//                     <MenuItem onClick={handleClose}>
//                         <Link color="primary" underline="none" to="/dashboard">
//                             Dashboard
//                         </Link>
//                     </MenuItem>
//                     <MenuItem onClick={handleClose} disableRipple>
//                         <Link to="/login">Login
//                         </Link>
//                     </MenuItem>
//                 </Menu>
//             </NavbarWrapper>
//         </>
//     );
// }
//
// const NavbarWrapper =  styled(Container)`
//     height: 80px;
//     background: #F1F2F4;
//     border-bottom: 1px solid #E5E5E5;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
// `
//
// const NavbarItems = styled(Container)`
//     margin: 0px !important;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
// `
import * as React from 'react';
import {useColorScheme} from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import NoteAdd from '@mui/icons-material/NoteAdd';
import Map from '@mui/icons-material/Map';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AddHomeIcon from "@mui/icons-material/AddHome";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Link from "@mui/joy/Link";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";

function ColorSchemeToggle() {
    const {mode, setMode} = useColorScheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="primary"/>;
    }
    return (
        <Tooltip title="Change theme" variant="outlined">
            <IconButton
                id="toggle-mode"
                size="sm"
                variant="plain"
                color="neutral"
                sx={{alignSelf: 'center'}}
                onClick={() => {
                    if (mode === 'light') {
                        setMode('dark');
                    } else {
                        setMode('light');
                    }
                }}
            >
                {mode === 'light' ? <DarkModeRoundedIcon/> : <LightModeRoundedIcon/>}
            </IconButton>
        </Tooltip>
    );
}

export default function Navbar(user) {
    const [open, setOpen] = React.useState(false);
    const {logout, login, isAuthenticated} = useKindeAuth();
    const userDetails = user.user.user
    
    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between',
            }}
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{display: {xs: 'none', sm: 'flex'}}}
            >
                <IconButton>
                    <AddHomeIcon/>
                </IconButton>
                <Link href={isAuthenticated ? '/dashboard' : `/`}>
                    <Typography level="h3">Toppi</Typography>
                </Link>
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    href="/dashboard/"
                    size="sm"
                    sx={{alignSelf: 'center'}}
                >
                    <IconButton
                        size="md"
                        variant="neutral"
                        color="neutral"
                    >
                        <NoteAdd/>
                    </IconButton>
                    Board
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    disabled={true}
                    component="a"
                    href="/joy-ui/getting-started/templates/team/"
                    size="sm"
                    sx={{alignSelf: 'center'}}
                >
                    <IconButton
                        size="md"
                        variant="neutral"
                        color="neutral"
                    >
                        <Map/>
                    </IconButton>
                    Map
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    disabled={true}
                    component="a"
                    href="/joy-ui/getting-started/templates/files/"
                    size="sm"
                    sx={{alignSelf: 'center'}}
                >
                    <IconButton
                        size="md"
                        variant="neutral"
                        color="neutral"
                    >
                        <ContentCopy/>
                    </IconButton>
                    Files
                </Button>
            </Stack>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1.5,
                    alignItems: 'center',
                }}
            >
                <Input
                    size="sm"
                    variant="outlined"
                    placeholder="Search anything…"
                    startDecorator={<SearchRoundedIcon color="primary"/>}
                    endDecorator={
                        <IconButton
                            variant="outlined"
                            color="neutral"
                            sx={{bgcolor: 'background.level1'}}
                        >
                            <Typography level="title-sm" textColor="text.icon">
                                ⌘ K
                            </Typography>
                        </IconButton>
                    }
                    sx={{
                        alignSelf: 'center',
                        display: {
                            xs: 'none',
                            sm: 'flex',
                        },
                    }}
                />
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    sx={{display: {xs: 'inline-flex', sm: 'none'}, alignSelf: 'center'}}
                >
                    <SearchRoundedIcon/>
                </IconButton>
                <ColorSchemeToggle/>
                {user.user.isAuthenticated ? (
                    <Dropdown>
                        <MenuButton
                            variant="plain"
                            size="sm"
                            sx={{maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px'}}
                        >
                            <Avatar
                                src="https://images7.alphacoders.com/331/thumbbig-331035.webp"
                                srcSet="https://images7.alphacoders.com/331/thumbbig-331035.webp"
                                sx={{maxWidth: '32px', maxHeight: '32px'}}
                            />
                        </MenuButton>
                        <Menu
                            placement="bottom-end"
                            size="sm"
                            sx={{
                                zIndex: '99999',
                                p: 1,
                                gap: 1,
                                '--ListItem-radius': 'var(--joy-radius-sm)',
                            }}
                        >
                            <MenuItem>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Avatar
                                        src="https://images7.alphacoders.com/331/thumbbig-331035.webp"
                                        srcSet="https://images7.alphacoders.com/331/thumbbig-331035.webp"
                                        sx={{borderRadius: '50%'}}
                                    />
                                    <Box sx={{ml: 1.5}}>
                                        <Typography level="title-sm" textColor="text.primary">
                                            {`${userDetails.given_name} ${userDetails.family_name}`}
                                        </Typography>
                                        <Typography level="body-xs" textColor="text.tertiary">
                                            {userDetails.email}
                                        </Typography>
                                    </Box>
                                </Box>
                            </MenuItem>
                            <ListDivider/>
                            <MenuItem onClick={logout}>
                                <LogoutRoundedIcon/>
                                Log out
                            </MenuItem>
                        </Menu>
                    </Dropdown>) : (
                    <Button
                        color="neutral"
                        onClick={login}
                        variant="soft"
                    >
                        Login
                    </Button>
                )}
            </Box>
        </Box>
    );
}